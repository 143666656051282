import { Button, Layout, Table } from 'antd';
import {
  Interval,
  addDays,
  format,
  getWeek,
  isBefore,
  isSunday,
  nextSunday,
  subDays,
} from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Pic from 'app/components/commons/Pic';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useHotelScore } from 'app/hooks/data/useHotelScore';
import {
  useAmplitude,
  useAmplitudeViewPage,
} from 'app/hooks/useAmplitude/useAmplitude';
import { useLocalStorage } from 'app/hooks/useLocalStorage';
import {
  tryFetchInventory,
  trySetHotelUnavailable,
} from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { urlParamSelector } from 'app/redux/selectors';
import { getUser } from 'app/redux/selectors/auth';
import {
  selectHotelAdminHotelId,
  selectHotelAdminHotels,
  selectHotelAdminInventory,
  selectHotelAdminIsEditing,
  selectHotelAdminIsLoading,
} from 'app/redux/selectors/hotelAdmin';
import { BulkEditInitialValues } from 'app/typings/bulkEdit';
import { ChannelManager } from 'app/typings/channelManagers';
import { RateMode } from 'app/typings/rateModes';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import { AutoInventoryModal } from './AutoInventory/AutoInventoryModal/AutoInventoryModal';
import BulkEdit from './BulkEdit/BulkEdit';
import './ExtranetInventory.scss';
import { HotelScore } from './HotelScore/HotelScore';
import InventoryHeader from './InventoryHeader/InventoryHeader';
import RoomRow from './RoomRow/RoomRow';
import {
  DuplicationHover,
  DuplicationMode,
  DuplicationModeData,
} from './Types';
import {
  NUMBER_OF_DAYS_RANGE,
  prepareExtranetInventory,
  setBusinessWeek,
} from './Utils';
import ValidationModal from './ValidationModal/ValidationModal';

const { Content } = Layout;
const { Column } = Table;

const PAGE = 'Inventory';

export const ExtranetInventory = ({ noStock }: Props) => {
  const { value: userActivity, setValue: setUserActivity } = useLocalStorage<{
    nbVisit: number;
    lastVisit: Date;
  }>('visitCount', { nbVisit: 1, lastVisit: new Date() });
  const { setPage } = useContext(TaskCenterContext);
  const dispatch = useAppDispatch();
  const inventory = useAppSelector(selectHotelAdminInventory);
  const hotelId = useAppSelector(selectHotelAdminHotelId);
  const isLoading = useAppSelector(selectHotelAdminIsLoading);
  const user = useAppSelector(getUser);
  const hotels = useAppSelector(selectHotelAdminHotels);
  const isEditing = useAppSelector(selectHotelAdminIsEditing);

  const history = useHistory();
  const location = useLocation();

  const [urlHasPricingRuleOpen, setUrlHasPricingRuleOpen] = useState(false);

  const [isAutoInventoryModalOpen, setIsAutoInventoryModalOpen] =
    useState(false);

  const { data: hotelScore, refetch: reloadHotelScore } =
    useHotelScore(hotelId);

  if (
    format(new Date(userActivity.lastVisit), 'yyyy-MM-dd') !==
    format(new Date(), 'yyyy-MM-dd')
  ) {
    setUserActivity({
      nbVisit: userActivity.nbVisit + 1,
      lastVisit: new Date(),
    });
  }

  useEffect(() => {
    const urlQueries = new URLSearchParams(location.search);

    if (urlQueries.has('pricingRuleModalOpen')) {
      setUrlHasPricingRuleOpen(true);
      urlQueries.delete('pricingRuleModalOpen');
      history.replace({
        search: '?' + urlQueries.toString(),
      });
    }
  }, [history, location.search, urlHasPricingRuleOpen]);

  setPage(PAGE);

  const hotel = hotels.find((h) => h.id === hotelId);
  const { track } = useAmplitude();

  const [currentRange, setCurrentRange] = useState<Interval>(
    setBusinessWeek(new Date())
  );
  const [isBulkEditOpen, setIsBulkEditOpen] = useState<boolean>(false);
  const [bulkEditInitialValues, setBulkEditInitialState] =
    useState<BulkEditInitialValues>({});
  const [currentDuplicateHoverRange, setCurrentDuplicateHoverRange] =
    useState<DuplicationHover>();

  const goToPreviousWeek = () =>
    setCurrentRange(
      setBusinessWeek(subDays(currentRange.start, NUMBER_OF_DAYS_RANGE))
    );

  const goToNextWeek = () => {
    const currentStart = currentRange.start;

    track('Click next sales arrow', {
      week_number: getWeek(addDays(currentStart, NUMBER_OF_DAYS_RANGE)),
    });
    setCurrentRange(
      setBusinessWeek(addDays(currentRange.start, NUMBER_OF_DAYS_RANGE))
    );
  };

  useEffect(() => {
    if (!!inventory) {
      reloadHotelScore();
    }
  }, [inventory, reloadHotelScore]);

  useEffect(() => {
    if (hotelId !== undefined) {
      dispatch(
        tryFetchInventory({
          hotelId,
          start: format(currentRange.start, 'yyyy-MM-dd'),
        })
      );
    }
  }, [dispatch, hotelId, currentRange]);

  useEffect(() => {
    if (noStock && hotelId) {
      dispatch(trySetHotelUnavailable({ hotelId }));
    }
  }, [noStock, dispatch, hotelId]);

  useAmplitudeViewPage({ page: 'inventory' });

  const filledInventory = useMemo(
    () =>
      prepareExtranetInventory(inventory, currentRange, hotel?.roomCount || 0),
    [inventory, currentRange, hotel?.roomCount]
  );

  const lastUpdate = useMemo(() => {
    if (!inventory) {
      return undefined;
    }

    const updates = inventory.rooms
      .flatMap((room) => room.openings.flatMap((opening) => opening.updatedAt))
      .sort((a, b) => {
        if (a && b) {
          return isBefore(new Date(a), new Date(b)) ? 1 : -1;
        } else if (a) {
          return 1;
        }

        return -1;
      });

    return updates?.[0];
  }, [inventory]);

  if (!filledInventory) {
    return null;
  }

  const handleDuplicateHover =
    (roomId: number) => (day: Date, hoverMode: DuplicationModeData) => {
      const getDaysToHover = (): Interval => {
        switch (hoverMode.mode) {
          case DuplicationMode.END_OF_WEEK:
            return { start: day, end: nextSunday(subDays(day, 1)) };

          case DuplicationMode.END_OF_NEXT_WEEK:
            if (!isSunday(day)) {
              return { start: day, end: nextSunday(addDays(day, 5)) };
            }

            return { start: day, end: nextSunday(day) };

          case DuplicationMode.END_OF_TABLE:
            return { start: day, end: currentRange.end };
          case DuplicationMode.CUSTOM:
            return { start: day, end: addDays(hoverMode.customDay, 1) };
          default:
            throw new Error('Unsupported Duplication mode');
        }
      };

      const range = getDaysToHover();

      setCurrentDuplicateHoverRange({ roomId, range });
    };

  const dropDuplicationHover = () => setCurrentDuplicateHoverRange(undefined);

  const isAdmin = user && (user.role === 'admin' || user.role === 'superadmin');

  const isManualHotel =
    hotel &&
    (!hotel.channelManager || hotel.channelManager === ChannelManager.NONE);

  const hasAutoInventoryActivated =
    (hotel && hotel.stockScrapperEnabled) ?? false;

  const renderChannelManagerHeader = (
    channelManager?: string,
    iconPath?: string,
    classPrefix?: string
  ) => {
    return (
      <div className="header">
        {!!channelManager && (
          <div className={`${classPrefix}-header`}>
            <div className={`${classPrefix}-header__thumbnail`}>
              <Pic pictureId={iconPath} />
            </div>
            {channelManager}
          </div>
        )}
        <div className="header__updated">
          {lastUpdate
            ? `Last update ${format(new Date(lastUpdate), 'dd/MM/yyyy HH:mm')}`
            : ''}
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    if (inventory && inventory.channelManager) {
      switch (inventory.channelManager) {
        case ChannelManager.SITEMINDER: {
          return renderChannelManagerHeader(
            `Siteminder`,
            'static/icons/siteminder.png',
            'siteminder'
          );
        }

        case ChannelManager.D_EDGE: {
          return renderChannelManagerHeader(
            `D-Edge`,
            'static/icons/d-edge.png',
            'dedge'
          );
        }

        case ChannelManager.RESERVIT: {
          return renderChannelManagerHeader(
            `Reservit`,
            'static/icons/reservit.png',
            'reservit'
          );
        }

        case ChannelManager.CUBILIS: {
          return renderChannelManagerHeader(
            'Cubilis',
            'static/icons/cubilis.png',
            'cubilis'
          );
        }

        case ChannelManager.TRAVELCLICK: {
          return renderChannelManagerHeader(
            'Travelclick',
            'static/icons/travelclick.png',
            'travelclick'
          );
        }

        case ChannelManager.ROOMCLOUD: {
          return renderChannelManagerHeader(
            'Roomcloud',
            'static/icons/roomcloud.png',
            'roomcloud'
          );
        }

        default: {
          return renderChannelManagerHeader();
        }
      }
    }

    return renderChannelManagerHeader();
  };

  const openBulkEdit = (
    clickEvent: React.MouseEvent<HTMLElement, MouseEvent>,
    bulkEditInitialValues: BulkEditInitialValues
  ) => {
    clickEvent.stopPropagation();
    track('Open Bulk Edit');
    setIsBulkEditOpen(true);
    setBulkEditInitialState(bulkEditInitialValues);
  };

  return (
    <>
      <Layout className="extranet-inventory">
        <ExtranetHeader
          page={PAGE}
          withWebsiteButton={isAdmin}
          withAutoInventoryButton={
            isManualHotel && !filledInventory.pkg.dayPackage
          }
          hasAutoInventoryActivated={hasAutoInventoryActivated}
          setIsAutoInventoryModalOpen={setIsAutoInventoryModalOpen}
          subTitle={renderHeader()}
        />

        <Content className="container">
          {!!hotelScore && !inventory?.pkg.dayPackage ? (
            <HotelScore
              isActive={userActivity.nbVisit < 4}
              isManualHotel={!!isManualHotel}
              openBulkEdit={(clickEvent) =>
                openBulkEdit(clickEvent, {
                  days: [5, 6],
                })
              }
              stockOptimisationScore={hotelScore.stockOptimisationScore}
              openAutoInventory={() => setIsAutoInventoryModalOpen(true)}
            />
          ) : (
            <div style={{ marginTop: 8 }}></div>
          )}
          <CurrencyWrapper hotel={hotel}>
            <InventoryHeader
              currentRange={currentRange}
              goToPreviousWeek={goToPreviousWeek}
              goToNextWeek={goToNextWeek}
              autoDiscountRate={hotel?.autoDiscountRate}
              lastMinuteAutoDiscountRate={hotel?.lastMinuteAutoDiscountRate}
              lastMinuteAutoDiscountDays={hotel?.lastMinuteAutoDiscountDays}
              isAdmin={isAdmin}
            />
            <Table
              dataSource={filledInventory.rooms || []}
              rowKey={(elem) => elem.roomName}
              rowClassName="extranet-inventory__table-row"
              loading={isLoading}
              expandable={{
                expandedRowRender: (room, index) => (
                  <RoomRow
                    room={room}
                    currentRange={currentRange}
                    currentDuplicateHoverRange={currentDuplicateHoverRange}
                    handleDuplicateHover={handleDuplicateHover}
                    dropDuplicationHover={dropDuplicationHover}
                    autoDiscountRate={hotel?.autoDiscountRate}
                    lastMinuteAutoDiscountRate={
                      hotel?.lastMinuteAutoDiscountRate
                    }
                    lastMinuteAutoDiscountDays={
                      hotel?.lastMinuteAutoDiscountDays
                    }
                    hasChannelManager={
                      (hotel?.channelManager &&
                        hotel.channelManager !== 'NONE') ??
                      false
                    }
                    stockScrapperEnabled={!!hotel?.stockScrapperEnabled}
                    urlTriggerPricingRule={
                      index === 0 ? urlHasPricingRuleOpen : false
                    }
                  />
                ),
                defaultExpandAllRows: true,
              }}
              pagination={false}
              expandRowByClick
            >
              <Column
                key="roomName"
                render={(elem) => (
                  <div className="rowTitle">
                    <div className="extranet-inventory__row">
                      {elem.roomName}
                    </div>
                    {!inventory?.channelManager ||
                    inventory.rateMode === RateMode.SINGLE_RATE ? (
                      <Button
                        key="bulk-edit-button"
                        type="link"
                        onClick={(clickEvent) =>
                          openBulkEdit(clickEvent, {
                            ...bulkEditInitialValues,
                            days: [1, 2, 3, 4, 5, 6, 7],
                            roomId: elem.room.id,
                          })
                        }
                        disabled={isEditing}
                      >
                        Bulk Edit
                      </Button>
                    ) : null}
                  </div>
                )}
              />
            </Table>
            {isBulkEditOpen && hotelId && (
              <BulkEdit
                setIsBulkEditOpen={setIsBulkEditOpen}
                isBulkEditOpen={isBulkEditOpen}
                initialValues={bulkEditInitialValues}
                currentRange={currentRange}
                inventory={inventory}
                hotelId={hotelId}
                autoDiscountRate={hotel?.autoDiscountRate}
              />
            )}
          </CurrencyWrapper>
        </Content>
      </Layout>

      <ValidationModal
        startDate={format(currentRange.start, 'yyyy-MM-dd')}
        autoDiscountRate={hotel?.autoDiscountRate}
        stockScrapperEnabled={!!hotel?.stockScrapperEnabled}
      />
      {hotel && inventory && isAutoInventoryModalOpen && (
        <CurrencyWrapper hotel={hotel}>
          <AutoInventoryModal
            hotel={hotel}
            hasAutoInventoryActivated={hasAutoInventoryActivated}
            inventory={inventory}
            isAutoInventoryModalOpen={isAutoInventoryModalOpen}
            onClose={() => setIsAutoInventoryModalOpen(false)}
            startDate={format(currentRange.start, 'yyyy-MM-dd')}
          />
        </CurrencyWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  noStock: urlParamSelector('noStock', false)(state),
});

type Props = {
  currentDate?: string;
  noStock: boolean;
};

export default connect(mapStateToProps)(ExtranetInventory);
