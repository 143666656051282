import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import SideMenuHotel from 'app/components/layout/SideMenuHotel';
import TaskCenterProvider from 'app/context/TaskCenterContext/TaskCenterContext';
import UsersnapProvider from 'app/context/UsersnapContext/UsersnapContext';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { useZendeskWidget } from 'app/hooks/useZendeskWidget/useZendeskWidget';
import { tryFetchHotels } from 'app/redux/actions/hotelAdmin';
import { initApp } from 'app/redux/actions/navigation';

import { ExtranetBills } from './pages/Extranet/ExtranetBills/ExtranetBills';
import ExtranetBookings from './pages/Extranet/ExtranetBookings';
import { ExtranetContact } from './pages/Extranet/ExtranetContact/ExtranetContact';
import { ExtranetDashboard } from './pages/Extranet/ExtranetDashboard/ExtranetDashboard';
import ExtranetFeedbacks from './pages/Extranet/ExtranetFeedbacks';
import { ExtranetInventory } from './pages/Extranet/ExtranetInventory/ExtranetInventory';
import ExtranetPackages from './pages/Extranet/ExtranetPackages/ExtranetPackages';
import { TaskCenter } from './pages/Extranet/commons/TaskCenter/TaskCenter';

const HotelApp = ({
  user,
  appUrl,
  fetchHotels,
  hotels,
  init,
  appReady,
  apiUrl,
  token,
  hotelId,
}) => {
  const { track } = useAmplitude();

  const hotel = hotels.find((h) => h.id === hotelId);

  useZendeskWidget();

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (appReady) {
      fetchHotels();
    }
  }, [appReady, fetchHotels, user]);

  if (!user || !appReady || !hotels) {
    return null;
  }

  if (
    user &&
    user.role === 'user' &&
    (!user.hotelIds || !user.hotelIds.length)
  ) {
    // TODO: see how we can handle this better
    return window.location.replace(appUrl);
  }

  return (
    <UsersnapProvider>
      <TaskCenterProvider hotel={hotel}>
        <Helmet>
          <title>Staycation - Extranet</title>
        </Helmet>
        <Layout className="app">
          <SideMenuHotel user={user} />
          {hotel && <TaskCenter hotel={hotel} />}
          <Switch>
            <Route path="/extranet/inventory" component={ExtranetInventory} />
            <Route path="/extranet/dashboard" component={ExtranetDashboard} />
            <Route path="/extranet/bookings" component={ExtranetBookings} />
            <Route path="/extranet/bills" component={ExtranetBills} />
            <Route path="/extranet/reviews" component={ExtranetFeedbacks} />
            <Route path="/extranet/packages" component={ExtranetPackages} />
            <Route
              path="/extranet/stripe-refresh/:hotelId/"
              component={(props) => {
                track('View Page', { page: 'payment' });
                window.location = `${apiUrl}/hotels/${props.match.params.hotelId}/stripe-session?token=${token}`;

                return null;
              }}
            />
            <Route path="/extranet/contact" component={ExtranetContact} />
            <Redirect
              to={`/extranet/inventory?hotelId=${user.hotelIds[0] ?? hotelId}`}
            />
          </Switch>
        </Layout>
      </TaskCenterProvider>
    </UsersnapProvider>
  );
};

HotelApp.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    hotelIds: PropTypes.array,
  }),
  appUrl: PropTypes.string.isRequired,
  fetchHotels: PropTypes.func,
  hotels: PropTypes.arrayOf(PropTypes.shape({})),
  appReady: PropTypes.bool,
  init: PropTypes.func,
  hotelId: PropTypes.number,
  apiUrl: PropTypes.string.isRequired,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  appUrl: state.navigation.appUrl,
  hotels: state.hotelAdmin.hotels,
  appReady: state.navigation.appReady,
  apiUrl: state.navigation.apiUrl,
  token: state.auth.token,
  hotelId: state.hotelAdmin.hotelId,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHotels: () => dispatch(tryFetchHotels()),
  init: () => dispatch(initApp('extranet')),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HotelApp)
);
