import { useEffect } from 'react';

const zendeskExtranetKey = process.env.REACT_APP_ZENDESK_EXTRANET_KEY as
  | string
  | undefined;

export const useZendeskWidget = () => {
  useEffect(() => {
    if (zendeskExtranetKey === undefined) {
      return;
    }

    const script = document.createElement('script');

    script.id = 'ze-snippet';
    script.defer = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskExtranetKey}`;

    document.head.appendChild(script);

    return () => {
      script.remove();
    };
  }, []);
};
