import { Checkbox, Col, Row } from 'antd';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';

import { useAppSelector } from 'app/redux/hooks';
import {
  OpeningForm,
  RoomOpening,
} from 'app/redux/models/RoomOpening/RoomOpening';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminIsEditing,
} from 'app/redux/selectors/hotelAdmin';
import { isPassed } from 'app/utils/dates';

import { computeRowClassNames } from '../../Utils';

type Props = {
  room: RoomOpening;
  currentRange: Interval;
  stockScrapperEnabled: boolean;
  handlePropertyChange: (
    dirtyOpening: OpeningForm | undefined,
    property: string,
    value: boolean
  ) => void;
};

export const StopSellRow = ({
  room,
  currentRange,
  stockScrapperEnabled,
  handlePropertyChange,
}: Props) => {
  const isEditing = useAppSelector(selectHotelAdminIsEditing);
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const bookableDays = useAppSelector(
    (s) => s.hotelAdmin.inventory?.saleDate.bookableDays ?? []
  );

  const renderPublished = (date: Date) => {
    const opening = room.openings.find(
      (o) => o.date === format(date, 'yyyy-MM-dd')
    );

    const dirtyOpening =
      dirtyOpenings.find(
        (o) =>
          o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
      ) || opening;

    if (!opening || !dirtyOpening) {
      return null;
    }

    return (
      <Col
        className={classNames(computeRowClassNames(date, bookableDays))}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${
          opening ? opening.roomId : ''
        }-stopsell`}
      >
        <Checkbox
          disabled={isPassed(date) || !isEditing}
          defaultChecked={dirtyOpening?.closed}
          checked={dirtyOpening?.closed}
          onChange={(e) =>
            handlePropertyChange(dirtyOpening, 'closed', e.target.checked)
          }
        />
      </Col>
    );
  };

  if (!stockScrapperEnabled || !room.room.bookingcomId || !isEditing) {
    return null;
  }

  return (
    <Row>
      <Col className="extranet-inventory__property-cell extranet-inventory__head">
        Stop sell
      </Col>
      {eachDayOfInterval(currentRange).map(renderPublished)}
    </Row>
  );
};

export default StopSellRow;
