import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';
import { connect } from 'react-redux';

import {
  OpeningForm,
  RoomOpening,
} from 'app/redux/models/RoomOpening/RoomOpening';
import { isPassed } from 'app/utils/dates';

import {
  DuplicationModeData,
  HotelSimple,
  Inventory,
  OpeningErrors,
} from '../../Types';
import { computeRowClassNames } from '../../Utils';
import AutoInventoryIcon from '../AutoInventoryIcon/AutoInventoryIcon';
import DuplicationMenu from '../DuplicationMenu/DuplicationMenu';

export const StockRow = ({
  hotel,
  dirtyOpenings,
  isEditing,
  inventory,
  room,
  currentRange,
  stockScrapperEnabled,
  handlePropertyChange,
  handleDuplicate,
  handleDuplicateHover,
  dropDuplicationHover,
}: Props) => {
  const hasChannelManager = !!inventory?.channelManager;

  const bookableDays = inventory?.saleDate.bookableDays ?? [];

  const renderStock = (date: Date) => {
    const opening = room.openings.find(
      (o) => o.date === format(date, 'yyyy-MM-dd')
    );

    const showIcon = stockScrapperEnabled && !!room.room.bookingcomId;

    const autoRow = opening
      ? opening.autoStock === true ||
        (opening.stock === 0 && opening.bar === undefined) ||
        opening.id === undefined
      : true;

    if (!isEditing) {
      const classes = [
        ...computeRowClassNames(date, bookableDays),
        {
          'extranet-inventory__property-cell--alert':
            !isPassed(date) &&
            opening &&
            (opening.bar || opening.discountPrice) &&
            opening.stock - opening.booked <= 0,
        },
      ];

      return (
        <Col
          className={classNames(classes)}
          flex="1"
          key={`${format(date, 'yyyy-MM-dd')}-${
            opening ? opening.roomId : ''
          }-stock`}
        >
          {!!opening && <span>{opening.stock - opening.booked}</span>}
          {showIcon && <AutoInventoryIcon autoRow={autoRow} />}
        </Col>
      );
    }

    const dirtyOpening =
      dirtyOpenings.find(
        (o) =>
          o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
      ) || opening;
    const isRoomError = dirtyOpening?.errorStatus?.includes(
      OpeningErrors.WRONG_STOCK
    );

    const tryDuplicate = (modeData: DuplicationModeData) => {
      handleDuplicate('stock', dirtyOpening?.stock || 0, date, modeData);
    };

    const renderInput = () => {
      const input = (
        <Input
          type="number"
          min={0}
          defaultValue={
            dirtyOpening &&
            opening &&
            (dirtyOpening.stock || 0) - opening.booked
          }
          disabled={isPassed(date) || hasChannelManager}
          onChange={(e) =>
            handlePropertyChange(
              dirtyOpening,
              'stock',
              (parseFloat(e.target.value) || 0) + (opening?.booked || 0)
            )
          }
          value={
            dirtyOpening?.stock !== undefined
              ? dirtyOpening.stock - (opening?.booked || 0)
              : 0
          }
          // Prevent the value to change when user scrolls.
          onWheel={(e) => e.currentTarget.blur()}
        />
      );

      if (hasChannelManager) {
        return input;
      }

      return (
        <div className="extranet-inventory__property-cell--input">
          {input}
          <DuplicationMenu
            date={date}
            currentRange={currentRange}
            tryDuplicate={tryDuplicate}
            handleDuplicateHover={handleDuplicateHover}
            dropDuplicationHover={dropDuplicationHover}
          />
        </div>
      );
    };

    return (
      <Col
        className={classNames([
          ...computeRowClassNames(date, bookableDays),
          {
            'extranet-inventory__property-cell--error': isRoomError,
          },
        ])}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${
          opening ? opening.roomId : ''
        }-stock`}
      >
        {renderInput()}
        {!!isRoomError &&
          !!dirtyOpening &&
          !!opening &&
          dirtyOpening.stock - opening.booked > 0 && (
            <div className="extranet-inventory__property-cell-error">
              {`Max room ${hotel.roomCount}`}
            </div>
          )}
        {showIcon && <AutoInventoryIcon autoRow={autoRow} />}
      </Col>
    );
  };

  const renderPackageTooltip = () => (
    <div className="extranet-inventory-tooltip__container">
      <div className="extranet-inventory-tooltip__title">
        Your stock can be updated at anytime from your D-edge interface.
      </div>
    </div>
  );

  return (
    <Row>
      <Col
        className={'extranet-inventory__property-cell extranet-inventory__head'}
      >
        <div className="extranet-inventory__head--align-content">
          Remaining stock
          {hasChannelManager && (
            <Tooltip
              title={() => renderPackageTooltip()}
              overlayClassName="extranet-inventory-tooltip"
              placement="topLeft"
            >
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
      </Col>
      {eachDayOfInterval(currentRange).map(renderStock)}
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  hotel: state.entities.hotels[state.hotelAdmin.hotelId],
  dirtyOpenings: state.hotelAdmin.dirtyOpenings,
  isEditing: state.hotelAdmin.isEditing,
  inventory: state.hotelAdmin.inventory,
});

type Props = {
  hotel: HotelSimple;
  dirtyOpenings: Array<OpeningForm>;
  isEditing: boolean;
  inventory?: Inventory;
  room: RoomOpening;
  currentRange: Interval;
  stockScrapperEnabled: boolean;
  handlePropertyChange: (
    dirtyOpening: OpeningForm | undefined,
    property: string,
    value: number
  ) => void;
  handleDuplicate: (
    property: 'bar' | 'stock' | 'discountPrice',
    value: number,
    day: Date,
    modeData: DuplicationModeData
  ) => void;
  handleDuplicateHover: (day: Date, hoverMode: DuplicationModeData) => void;
  dropDuplicationHover: () => void;
};

export default connect(mapStateToProps)(StockRow);
