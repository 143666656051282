import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import {
  stopLoading,
  toggleIsEditing,
  toggleValidationFailed,
  toggleViewValidationFailed,
  trySaveOpenings,
} from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { getUser } from 'app/redux/selectors/auth';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminHotelId,
  selectHotelAdminInventory,
  selectHotelAdminIsEditing,
  selectHotelAdminIsLoading,
  selectHotelAdminIsValidationFailed,
  selectHotelAdminIsViewValidationFailed,
} from 'app/redux/selectors/hotelAdmin';
import { RateMode } from 'app/typings/rateModes';
import {
  getChannelManagerLabel,
  getChannelManagerLink,
} from 'app/utils/channelManagers/channelManagers';

import { PricingRuleBulkEditAlert } from '../RoomRow/AutomatedDiscount/_components/PricingRuleBulkEditAlert';
import { ByErrorOpenings, OpeningErrors } from '../Types';

import './ValidationModal.scss';

type Props = {
  startDate: string;
  autoDiscountRate?: number;
  stockScrapperEnabled: boolean;
};

export const ValidationModal = ({
  startDate,
  autoDiscountRate,
  stockScrapperEnabled,
}: Props) => {
  const hotelId = useAppSelector(selectHotelAdminHotelId);
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const isValidationFailed = useAppSelector(selectHotelAdminIsValidationFailed);
  const isViewValidationFailed = useAppSelector(
    selectHotelAdminIsViewValidationFailed
  );
  const isLoading = useAppSelector(selectHotelAdminIsLoading);
  const isEditing = useAppSelector(selectHotelAdminIsEditing);
  const user = useAppSelector(getUser);
  const inventory = useAppSelector(selectHotelAdminInventory);

  const dispatch = useAppDispatch();
  const { track } = useAmplitude();

  const shouldEditOnChannelManager =
    user?.role !== 'admin' &&
    user?.role !== 'superadmin' &&
    inventory?.rateMode === RateMode.MULTI_RATE;

  const byErrorOpenings = useMemo(
    () =>
      dirtyOpenings.reduce(
        (acc: ByErrorOpenings, op) => {
          if (!op.errorStatus || op.published) {
            return acc;
          }

          if (op.errorStatus.includes(OpeningErrors.MISSING_STOCK)) {
            acc.missingStock.push(op);
          }

          if (op.errorStatus.includes(OpeningErrors.WRONG_STOCK)) {
            acc.wrongStock.push(op);
          }

          if (op.errorStatus.includes(OpeningErrors.EXCEED_RATE)) {
            acc.exceededRate.push(op);
          }

          if (op.errorStatus.includes(OpeningErrors.MISSING_BAR)) {
            acc.missingBar.push(op);
          }

          if (op.errorStatus.includes(OpeningErrors.MISSING_DISCOUNT_PRICE)) {
            acc.missingDiscountPrice.push(op);
          }

          if (op.errorStatus.includes(OpeningErrors.UNDER_MINIMUM_PRICE)) {
            acc.underMinimumPrice.push(op);
          }

          if (op.errorStatus.includes(OpeningErrors.CLOSED)) {
            acc.closed.push(op);
          }

          // blocked by Staycation
          if (op.isForced) {
            acc.blockedByStaycation.push(op);
          }

          return acc;
        },
        {
          exceededRate: [],
          wrongStock: [],
          missingStock: [],
          missingBar: [],
          missingDiscountPrice: [],
          underMinimumPrice: [],
          blockedByStaycation: [],
          closed: [],
        }
      ),
    [dirtyOpenings]
  );

  const openingsWithErrors = useMemo(
    () =>
      dirtyOpenings.filter(
        (op) =>
          (!op.published && op.errorStatus && op.errorStatus.length) ||
          (!op.published && op.isForced)
      ),
    [dirtyOpenings]
  );

  const getDeautomatedOpenings = useCallback(() => {
    const allAutomatedOpenings = inventory?.rooms.flatMap((room) =>
      room.openings.filter((el) => el.autoDiscount === true)
    );

    return allAutomatedOpenings?.filter((opening) =>
      dirtyOpenings.some((dirtyOpening) => opening.id === dirtyOpening.id)
    );
  }, [inventory, dirtyOpenings]);

  const onResolve = useCallback(() => {
    track('Click Resolve Errors', {
      nbAutoDiscountDeactivated: autoDiscountRate
        ? getDeautomatedOpenings()?.length
        : 0,
    });

    if (shouldEditOnChannelManager && inventory?.channelManager) {
      window.location.replace(getChannelManagerLink(inventory.channelManager));
    } else if (isValidationFailed) {
      dispatch(toggleValidationFailed({ value: false }));
      dispatch(stopLoading());
    } else {
      dispatch(toggleIsEditing({ value: true }));
      dispatch(toggleViewValidationFailed({ value: false }));
      dispatch(stopLoading());
    }
  }, [
    dispatch,
    autoDiscountRate,
    isValidationFailed,
    shouldEditOnChannelManager,
    inventory?.channelManager,
    getDeautomatedOpenings,
    track,
  ]);

  const onIgnore = useCallback(() => {
    track('Click Ignore Errors', {
      nbAutoDiscountDeactivated: autoDiscountRate
        ? getDeautomatedOpenings()?.length
        : 0,
    });

    if (!hotelId) {
      return;
    }

    if (isValidationFailed) {
      dispatch(
        trySaveOpenings({
          hotelId,
          openings: dirtyOpenings,
          start: startDate,
          inventory,
        })
      );
    } else {
      dispatch(toggleViewValidationFailed({ value: false }));
      dispatch(stopLoading());
    }
  }, [
    dispatch,
    autoDiscountRate,
    isValidationFailed,
    hotelId,
    startDate,
    dirtyOpenings,
    inventory,
    getDeautomatedOpenings,
    track,
  ]);

  // useEffect(() => {
  //   if (openingsWithErrors.length) {
  //     const errors = Object.entries(byErrorOpenings).map((tuple) => {
  //       const [key, value] = tuple;

  //       if (!!value.length) {
  //         return key;
  //       }

  //       return undefined;
  //     });

  //     track('Gestion des erreurs', {
  //       errors: errors.filter((e) => !!e),
  //     });
  //   }
  // }, [byErrorOpenings, openingsWithErrors.length, track]);

  const renderFooter = () => {
    if (inventory?.channelManager && shouldEditOnChannelManager) {
      return [
        <Button onClick={onIgnore}>Ignore</Button>,
        <Button type="primary" onClick={onResolve}>
          {`Edit on ${getChannelManagerLabel(inventory.channelManager)}`}
        </Button>,
      ];
    }

    if (isEditing && isViewValidationFailed) {
      return null;
    }

    return [
      <Button onClick={onIgnore} loading={isLoading}>
        Ignore
      </Button>,
      <Button type="primary" onClick={onResolve} loading={isLoading}>
        Resolve
      </Button>,
    ];
  };

  return (
    <Modal
      width={750}
      open={isValidationFailed || isViewValidationFailed}
      title={`${openingsWithErrors.length} ${
        openingsWithErrors.length > 1 ? 'availabilities' : 'availability'
      } won't be validated`}
      onOk={onResolve}
      maskClosable={false}
      onCancel={onIgnore}
      closable={false}
      className="extranet-inventory-modal"
      footer={renderFooter()}
      bodyStyle={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}
      centered
    >
      {!!byErrorOpenings.exceededRate.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.exceededRate.length} Staycation ${
                byErrorOpenings.exceededRate.length > 1 ? 'rates' : 'rate'
              }`}
            </span>
            {` ${
              byErrorOpenings.exceededRate.length > 1 ? 'are' : 'is'
            } above maximum accepted`}
          </h3>
          <ul>
            {byErrorOpenings.exceededRate.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.underMinimumPrice.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.underMinimumPrice.length} Staycation ${
                byErrorOpenings.underMinimumPrice.length > 1 ? 'rates' : 'rate'
              }`}
            </span>
            {` ${
              byErrorOpenings.underMinimumPrice.length > 1 ? 'are' : 'is'
            } under minimum accepted`}
          </h3>
          <ul>
            {byErrorOpenings.underMinimumPrice.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.missingDiscountPrice.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.missingDiscountPrice.length} Staycation ${
                byErrorOpenings.missingDiscountPrice.length > 1
                  ? 'rates'
                  : 'rate'
              }`}
            </span>
            {` ${
              byErrorOpenings.missingDiscountPrice.length > 1 ? 'are' : 'is'
            } missing`}
          </h3>
          <ul>
            {byErrorOpenings.missingDiscountPrice.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.missingStock.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.missingStock.length} remaining ${
                byErrorOpenings.missingStock.length > 1 ? 'stocks' : 'stock'
              }`}
            </span>
            {` ${
              byErrorOpenings.missingStock.length > 1 ? 'are' : 'is'
            } missing`}
          </h3>
          <ul>
            {byErrorOpenings.missingStock.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.wrongStock.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.wrongStock.length} remaining ${
                byErrorOpenings.wrongStock.length > 1 ? 'stocks' : 'stock'
              }`}
            </span>
            {` ${
              byErrorOpenings.wrongStock.length > 1 ? 'are' : 'is'
            } above maximum accepted`}
          </h3>
          <ul>
            {byErrorOpenings.wrongStock.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.missingBar.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.missingBar.length} BAR RO`}
            </span>
            {byErrorOpenings.missingBar.length > 1
              ? ' are missing '
              : ' is missing '}
          </h3>
          <ul>
            {byErrorOpenings.missingBar.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.blockedByStaycation.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.blockedByStaycation.length} ${
                byErrorOpenings.blockedByStaycation.length > 1
                  ? 'availabilities'
                  : 'availability'
              } `}
            </span>
            {`${
              byErrorOpenings.blockedByStaycation.length > 1 ? 'have' : 'has'
            } been blocked by Staycation`}
          </h3>
          <ul>
            {byErrorOpenings.blockedByStaycation.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {!!byErrorOpenings.closed.length && (
        <>
          <h3>
            <span className="extranet-inventory-modal__danger">
              <ExclamationCircleFilled />
              {`${byErrorOpenings.closed.length} ${
                byErrorOpenings.closed.length > 1
                  ? 'availabilities'
                  : 'availability'
              } `}
            </span>
            {stockScrapperEnabled
              ? byErrorOpenings.closed.length > 1
                ? `have a stop sell applied`
                : `has a stop sell applied`
              : byErrorOpenings.closed.length > 1
              ? `are closed on your channel manager`
              : `is closed on your channel manager`}
          </h3>
          <ul>
            {byErrorOpenings.closed.map((op) => (
              <li key={`${op.name}-${op.date}`}>
                {`${op.name} - ${format(new Date(op.date), 'EEE dd/MM')}`}
              </li>
            ))}
          </ul>
        </>
      )}
      {autoDiscountRate && <PricingRuleBulkEditAlert />}
    </Modal>
  );
};

export default ValidationModal;
